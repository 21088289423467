import { E_PipServiceMode } from "@shared/constants";
import { I_Referrer } from "../interfaces/referrer";
import { Metric } from "./metric";

export namespace PipMetrics {
  export class MetricProperties {
    public referrer: I_Referrer;
    public os: string;
    public deviceName: string;
    public browser: string;
    public mode: E_PipServiceMode;
    public os_version: string;
    public browser_version: string;
  }

  export class InvalidCodeEntered extends Metric {
    constructor(siteId: string, os: string, deviceName: string, browser: string, browser_version: string, os_version: string, mode: E_PipServiceMode) {
      super("Invalid Code Entered", siteId, { os, deviceName, browser, browser_version, os_version, mode });
    }
  }

  export class DevicePaired extends Metric {
    constructor(siteId: string, os: string, deviceName: string, browser: string, browser_version: string, os_version: string, mode: E_PipServiceMode) {
      super("Device Paired", siteId, { os, deviceName, browser, browser_version, os_version, mode });
    }
  }

  export class CheckInStarted extends Metric {
    constructor(siteId: string, os: string, deviceName: string, browser: string, browser_version: string, os_version: string, mode: E_PipServiceMode) {
      super("Patient Check in Started", siteId, { os, deviceName, browser, browser_version, os_version, mode });
    }
  }

  export class CheckInDobSubmitted extends Metric {
    constructor(siteId: string, os: string, deviceName: string, browser: string, browser_version: string, os_version: string, mode: E_PipServiceMode) {
      super("Patient DOB Submitted", siteId, { os, deviceName, browser, browser_version, os_version, mode });
    }
  }

  export class CheckInDaySelected extends Metric {
    constructor(siteId: string, os: string, deviceName: string, browser: string, browser_version: string, os_version: string, mode: E_PipServiceMode) {
      super("Patient Check in Day Selected", siteId, { os, deviceName, browser, browser_version, os_version, mode });
    }
  }

  export class CheckInMonthSelected extends Metric {
    constructor(siteId: string, os: string, deviceName: string, browser: string, browser_version: string, os_version: string, mode: E_PipServiceMode) {
      super("Patient Check in Month Selected", siteId, { os, deviceName, browser, browser_version, os_version, mode });
    }
  }

  export class CheckInYearEntered extends Metric {
    constructor(siteId: string, os: string, deviceName: string, browser: string, browser_version: string, os_version: string, mode: E_PipServiceMode) {
      super("Patient Check in Year Entered", siteId, { os, deviceName, browser, browser_version, os_version, mode });
    }
  }

  export class CheckInSurnameEntered extends Metric {
    constructor(siteId: string, os: string, deviceName: string, browser: string, browser_version: string, os_version: string, mode: E_PipServiceMode) {
      super("Patient Check in Surname Entered", siteId, { os, deviceName, browser, browser_version, os_version, mode });
    }
  }

  export class CheckInPatientNotFound extends Metric {
    constructor(siteId: string, os: string, deviceName: string, browser: string, browser_version: string, os_version: string, mode: E_PipServiceMode) {
      super("Patient Check in Patient Not Found", siteId, { os, deviceName, browser, browser_version, os_version, mode });
    }
  }

  export class CheckInFirstNameEntered extends Metric {
    constructor(siteId: string, os: string, deviceName: string, browser: string, browser_version: string, os_version: string, mode: E_PipServiceMode) {
      super("Patient Check in First Name Entered", siteId, { os, deviceName, browser, browser_version, os_version, mode });
    }
  }

  export class CheckInDeviceLocked extends Metric {
    constructor(siteId: string, os: string, deviceName: string, browser: string, browser_version: string, os_version: string, mode: E_PipServiceMode) {
      super("Patient Check in Device Locked", siteId, { os, deviceName, browser, browser_version, os_version, mode });
    }
  }

  export class CheckInDeviceUnlocked extends Metric {
    constructor(siteId: string, os: string, deviceName: string, browser: string, browser_version: string, os_version: string, mode: E_PipServiceMode) {
      super("Patient Check in Device Unlocked", siteId, { os, deviceName, browser, browser_version, os_version, mode });
    }
  }

  export class CheckedIn extends Metric {
    constructor(siteId: string, os: string, deviceName: string, browser: string, browser_version: string, os_version: string, mode: E_PipServiceMode) {
      super("Patient Checked in", siteId, { os, deviceName, browser, browser_version, os_version, mode });
    }
  }

  export class DeviceLocked extends Metric {
    constructor(siteId: string, os: string, deviceName: string, browser: string, browser_version: string, os_version: string, mode: E_PipServiceMode) {
      super("Device Locked", siteId, { os, deviceName, browser, browser_version, os_version, mode });
    }
  }

  export class DeviceUnlocked extends Metric {
    constructor(siteId: string, os: string, deviceName: string, browser: string, browser_version: string, os_version: string, mode: E_PipServiceMode) {
      super("Device Unlocked", siteId, { os, deviceName, browser, browser_version, os_version, mode });
    }
  }
}
